export const breakpoints = {
  xs: "480px",
  sm: "576px",
  md: "768px",
  lg: "1350px",
  xl: "1500px",
}

export const palette = {
  primary: "#020f73",
  primaryContrast: "#f5f5f5",
  primaryContrastStong: "#fff",
  primaryContrastLight: "#c7c7c7",

  secondary: "#2e41cf",
  secondaryLight: "#2e7ecf",
}
