/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Footer from "./footer"
import "./layout.css"
//import LiveChat from "./live-chat/livechat"

import { Helmet } from "react-helmet"

import Navbar from "./navbar/navbar"

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]')
}

const Layout = ({ children }) => {
  return (
    <div>
      <Helmet>
        <script
          defer
          src="https://unpkg.com/@tinybirdco/flock.js"
          data-host="https://api.tinybird.co"
          data-token="p.eyJ1IjogIjJiYmM0NzVlLTkyOTctNDliYy05YmZjLWQ3OWFmZmY2N2U0NyIsICJpZCI6ICI2ZTBmYzk0ZC1kY2U2LTQyMTctYjdkNi03NDJhODhkMmJkZDUifQ.n-2YUe_NTvMSU6IrAUcrp0Fn4E2ZG-vdlIoNKuYGAI0"
        ></script>
      </Helmet>
      <Navbar />
      <main>{children}</main>
      <Footer></Footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]')
}

export default Layout
